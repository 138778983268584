@import '../../../../variables';

.main {
    width: 198px;
    min-height: 78px;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    background-color: $widget-container-background-color;
    flex-direction: row;
    position: relative;
    &:hover {
        background-color: #f5f5f5;
    }
}

.addThisWidget {
    opacity: 0;
    position: absolute;
    bottom: 0px;
    background-color: black;
    color: $widget-container-background-color;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    text-align: center;
    margin-top: -22px;
    :hover > & {
        opacity: 0.7;
    }
}

.imageContainer {
    width: 34px;
    height: 36px;
    padding: 20px 0px 0px 8px;
}

.image {
    width: 34px;
    height: 36px;
    max-width: unset;
}

.textContainer {
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 14px;
    font-weight: 600;
}

.desc {
    opacity: 0.7;
    font-size: 10px;
    line-height: 1.5;
}

.customDragLayer {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}
