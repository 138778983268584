@import '../../../../../variables';
$search-input-width: 250px;

.container {
    border-radius: 6px;
    border: dashed 2px $primary-color;
    background-color: #fafafa;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    margin-top: 15px;
    transition: height 0.5s ease;
    max-width: $widget-max-width - $widget-inline-menu-border-width;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    position: relative;
    gap: 16px;
}

.header::before {
    content: '';
    width: $search-input-width;
    order: 1;
}

.title {
    text-align: center;
    font-family: $main-Font;
    order: 2;
}

.searchInputContainer {
    order: 3;
}

.searchInput {
    width: $search-input-width;
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
}

.widgetsList {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.listContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.widgetCard {
    font-family: $main-Font;
}

.widgetCardText {
    font-size: 10px;
}

.widgetCardTitle {
    font-size: 12px;
}

.emptyState {
    width: 100%;
    text-align: center;
    padding: 40px 20px;
    color: $empty-state-text-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    p {
        margin: 8px 0;
        font-family: $main-Font;
    }
}

@media (max-width: $medium-laptop) {
    .header {
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
    }

    .title {
        position: relative;
        left: auto;
        transform: none;
        pointer-events: auto;
        width: 100%;
        text-align: center;
        order: unset;
    }

    .searchInputContainer {
        order: unset;
    }
}