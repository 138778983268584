@import '../../variables';

.selectContainer {
  position: relative;
}

.buttonContainer {
  display: flex;
}

.button {
  height: 30px;
}

.floatingWindow {
  z-index: 15;
  width: 35vw;
  max-width: 550px;
  position: absolute;
}

.floatingWindowContent {
  padding: 10px;
}

.buttonLabel {
  padding: 3px 10px;
  white-space: nowrap;
}

.buttonText {
  font-size: 16px;
  padding-right: 10px;
}

.buttonSelectedValues {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px 10px;
  font-size: 16px;
}

.valuesBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.selectedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-color;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}