@import '../../../variables';

.searchLine {
    display: flex;
    align-items: baseline;
    gap: 16px;
    padding: 30px 36px 22px 40px;
    border-bottom: 1px solid $widget-container-border-color;

    :global(.MuiInputBase-root) {
        border: none;
        outline: none;
        font-family: $main-Font;
    }

    :global(.MuiInput-underline:before),
    :global(.MuiInput-underline:after),
    :global(.MuiInput-underline:hover) {
        content: none;
        border-bottom: none;
    }    
}
