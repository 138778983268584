@import '../../variables';

.borderlessContainer {
  border: none;
  margin: 10px 0 0;
}

.widgetsListTitle {
  margin-top: 0;
  font-size: 14px;
  font-weight: normal;
}

.unknownWidgetTitle {
  // Needed to override due to style nesting in WidgetTitle
  padding-bottom: 0 !important;
}

.widgetsList {
  margin-top: 15px;
}

.viewModeContainer {
  margin-top: -10px;

  &.viewModeWithoutTitle {
    margin-top: 0;
  }

  .viewModeText {
    font-family: $main-Font;
    font-size: 12px;
    font-weight: 600;
    color: $primary-branded-color;
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
}

@media (max-width: $small-laptop) {
  .widgetsList {
    [class*='imageContainer'], [class*='desc'] {
      display: none;
    }
  }
}