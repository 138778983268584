@import '../../variables';

$LIVE_TAG_BG_COLOR: #218a8a;
$LIVE_TAG_COLOR: #ffffff;
$DRAFT_TAG_BG_COLOR: #f8f0cb;
$DRAFT_TAG_COLOR: #000000;
$UNPUBLISHED_TAG_BG_COLOR: #fd750b;
$UNPUBLISHED_TAG_COLOR: #ffffff;
$ONE_TIME_TAG_BG_COLOR: #d7dadc;

.tag {
    white-space: nowrap;
}

.live {
    color: $LIVE_TAG_COLOR;
    background-color: $LIVE_TAG_BG_COLOR;
}

.draft {
    color: $DRAFT_TAG_COLOR;
    background-color: $DRAFT_TAG_BG_COLOR;
}

.unpublished, .stopped {
    color: $UNPUBLISHED_TAG_COLOR;
    background-color: $UNPUBLISHED_TAG_BG_COLOR;
}

.oneTime {
    color: #000;
    background-color: $ONE_TIME_TAG_BG_COLOR;
}
