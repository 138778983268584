.clickable {
    width: 100%;
    height: 100%;
    > * {
        height: 100%;
        width: 100%;
        padding: 6px 16px;
    }
}

.infoIcon {
    margin-left: 5px;
}

.infoTooltip {
    width: fit-content;
}