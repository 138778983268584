@import '../../../variables';


.chip {
    display: inline-flex;
    align-items: center;
    padding: 0px 8px;
    background-color: $white-color;
    border-radius: 16px;
    font-size: 12px;
    border: solid 1px #dedede;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: $all-accounts-hover-color;
    }
}

.skeleton {
    width: 80px;
    height: 14px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 16px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
} 