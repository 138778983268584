@import '../../variables';

.dialogContentContainer {
    width: 960px;
    background-color: white;
    padding-bottom: 16px;
    overflow-y: scroll;
    border-radius: 5px;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    padding: 0 36px 0 28px;
    padding-bottom: 4px;
    min-height: 140px;
    overflow-y: scroll;
    max-height: calc(100vh - 265px);
}

.footerText {
    font-size: 14px;
    color: $widget-container-background-color;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.folderText {
    color: $subtext-light-grey;
    font-size: 12px;
}

.separator {
    height: 1px;
    background-color: $separator-color;
    margin-top: 16px;
    margin-left: 11px;
}

.noResults {
    height: 112px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $empty-state-text-color;
}
