@import '../../../variables';

.accountsContainer {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.sectionTitle {
    font-size: 14px;
    color: $subtext-grey;
    padding-bottom: 6px;
    padding-left: 11px;
}

.toggleContainer {
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: -10px;
    margin-left: 11px;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.dividerContainer {
    margin-top: 4px;
    display: flex;
    margin-left: 11px;
    flex: 1;
}

.divider {
    flex: 1;
    height: 1px;
    background-color: $separator-color;
}

.rotate {
    transform: rotate(180deg);
}

.arrowIcon {
    g {
        fill: $primary-branded-color;
    }
}

.separator {
    height: 1px;
    background-color: $separator-color;
    margin: 10px 0;
    margin-left: 11px;
}
