@import '../../../variables';

.archivedBadge {
    position: relative;
    top: 19px;
    left: 44px;
    background-color: $widget-gallery-background-color;
    height: 12px;
    width: 42px;
    font-size: 9px;
    padding: 0 3px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    z-index: 10;
}

.accountContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 4px;
    padding: 11px 16px 15px 11px;
    position: relative;
    &:hover {
        background-color: $select-options-hover-highlight;
        cursor: pointer;
    }
}

.AccountDetails {
    display: flex;
    gap: 10px;
    align-items: center;
    min-width: 0;
}

.accountLogo {
    flex-shrink: 0;
    width: 23px;
    height: 23px;
    text-align: center;
    line-height: 27px;
    font-size: 24px;

    > div {
        text-align: center;
        line-height: 24px;
        font-size: 22px;
    }

    &.archived {
        filter: grayscale(100%);
    }
}

.accountOwnerName {
    font-size: 14px;
    display: inline-block;
    @include ellipsis();
    &:before {
        content: '-';
        padding-right: 10px;
    }
}

.title {
    display: inline-block;
    @include ellipsis();
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
}

.assetsList {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding-left: 34px;
}