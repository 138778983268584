@import '../../variables.scss';

.tag {
    line-height: 1;
    border-radius: 40px;
    box-shadow: 0 0 0 1px $tag-shadow-color;
    background-color: $tag-background-color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    transition: box-shadow 0.6s linear;
    font-size: 20px;
    color: $tag-color;

    &.disabled {
        opacity: 0.7;
        pointer-events: none;
    }
}

.tag:not(.disabled):hover {
    box-shadow: 0 0 0 2px $tag-shadow-color;
    cursor: pointer;
}

.label {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 20px;
    max-width: 98%;
}
