.badge {
    margin-right: 25px;
    margin-top: 25px;
    max-width: 100%;
    word-break: break-word;
    min-height: 36px;
    box-sizing: border-box;

    @media (max-width: 600px) {
        margin-right: 11px;
        margin-top: 22px;
    }
}

.emptyState {
    font-size: 14px;
    opacity: 0.7;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 16px;
    padding: 0 16px;
}

.searchContainer {
    width: 235px;
    margin: -1px 0 0 10px;
}

.filterSelect {
    width: 400px;
}