@import '../../../variables';

.kitsContainer {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.kitItem {
    width: 46%;
    display: flex;
    align-items: flex-start;
    padding: 8px 11px;
    gap: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    height: 42px;

    &:hover {
        background-color: $select-options-hover-highlight;
        cursor: pointer;
    }
}

.kitContent {
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.kitName {
    display: flex;
    align-items: center;
    gap: 14px;
}

.kitNameText {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
    @include ellipsis();
}

.kitFolder {
    font-size: 12px;
    color: #666;
    padding-left: 30px;
    display: inline-block;
    @include ellipsis();
}

.kitIcon {
    height: 16px;
    width: 16px;

    path:nth-child(1) {
        stroke: #000000;
    }

    path:nth-child(2),
    path:nth-child(3) {
        fill: #000000;
    }
}

.sectionTitle {
    font-size: 14px;
    color: $subtext-grey;
    padding-bottom: 6px;
    padding-left: 11px;
}

.kitsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
