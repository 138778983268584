@import '../../variables';

$description-text-color: $action-button-description;

.header {
    display: flex;
    margin: 30px auto 0;
    text-align: center;
    flex-direction: column;
    align-items: center;

    &.narrow {
        width: 400px;
    }
}

.title {
    font-size: 18px;
    margin-bottom: 5px;
}

.description {
    font-size: 14px;
}

.connectImg {
    margin-top: 20px;
}

.description {
    color: $description-text-color;
    font-size: 12px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}