@import '../../../variables';

.journeysContainer {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.journeyItem {
    width: 46%;
    display: flex;
    align-items: flex-start;
    padding: 8px 14px 8px 8px;
    gap: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    height: 42px;

    &:hover {
        background-color: $select-options-hover-highlight;
        cursor: pointer;
    }
}

.journeyContent {
    display: flex;
    flex-direction: column;
}

.journeyName {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    @include ellipsis();
}

.journeyNameText {
    @include ellipsis();
}

.journeyFolder {
    font-size: 12px;
    color: #666;
    padding-left: 32px;
    @include ellipsis();
}

.sectionTitle {
    font-size: 14px;
    color: $subtext-grey;
    padding-bottom: 6px;
    padding-left: 11px;
}

.journeysList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.journeyInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 2px;
}

.tag {
    padding: 0 8px;
}
